import React from 'react';
import { Link } from 'react-router-dom';

import srLogo from '../assets/images/sr-hearth.png';
import imgTitle from '../assets/images/hello.svg';
import iconAlert from '../assets/images/icon_alert.svg';

const Intro = () => {
  return (
    <div className="container-fluid intro-bg">
      <div className="row h-100 justify-content-center align-items-center align-content-center">
        <div className="col-12 content d-flex flex-column text-center justify-content-center align-items-center align-content-center">
          <a href="https://www.sweetrush.com" target="_blank" rel="noreferrer">
            <img className="logo" src={srLogo} alt="" />
          </a>
          <img className="title" src={imgTitle} alt="" />
          <p>
            To express our appreciation, we have a small gift for you... something to help you relax
            and unwind!
          </p>
          <p>
            But first, if you don&apos;t mind, we have some questions that&apos;ll help us customize
            just for you.
          </p>
          <p>Ready to begin?</p>

          <Link className="link" to="/assessment">
            Start
          </Link>

          <p className="disclaimer-mini">
            NOTE: Although this experience was made with lots of love, we’ll admit it’s not based on
            actual science. We used knowledge and common sense—along with many hours of Google
            search—to build it. Please don’t use the results as a behavioral report for your next
            interview. What about your data? We are collecting none, nothing, not a trace!
          </p>
        </div>
      </div>

      <div className="disclaimer-devices">
        <img src={iconAlert} alt="icon alert" />
        For the best experience, please take this assessment on a larger device, such as a tablet or
        desktop computer.
      </div>
    </div>
  );
};

export default Intro;
